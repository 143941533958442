"use strict";

class RTC {
    constructor(onMessage = () => null, onIce = () => null, onOpen = () => null) {
        this.onMessage = onMessage;
        this.onIce = onIce;
        this.onOpen = onOpen;
    }

    _connect(iceServers) {
        console.log("Initialising RTC")
        const options = {
            "iceServers": iceServers
        };

        if (this.peerConnection) {
            console.log("There was an open connection - closing it")
            this.peerConnection.close();
        }

        this.peerConnection = new RTCPeerConnection(options);
        this.peerConnection.onerror = (err) => console.error("connection error", err);
        this.peerConnection.onicecandidate = this._onIceCandidate.bind(this);
    
        console.log("RTC initialised");
    }

    _onRecieveMessage(event) {
        const message = event.data;
        this.onMessage(message);
    }

    sendPacket(data) {
        if (this.dataChannel.readyState !== "open") {
            console.error("Could not send packet, data channel wasnt open");
            return;
        }

        this.dataChannel.send(data);
    }

    setRemoteDescription(description) {
        const sdp = new RTCSessionDescription(description);
        return this.peerConnection.setRemoteDescription(sdp);
    }

    createOffer() {
        console.log("Creating offer");
        return this.peerConnection.createOffer()
            .then(desc => {
                console.log("Offer created");
                return this.peerConnection.setLocalDescription(desc).then(() => desc);
            });
    }

    createAnswer() {
        console.log("Creating answer");
        return this.peerConnection.createAnswer().then(desc => {
            console.log("Answer created");
            return this.peerConnection.setLocalDescription(desc).then(() => desc);
        });
    }

    _onIceCandidate(event) {
        if (event.candidate == null) {
            console.log("Dropping null ICE candidate");
            return;
        }

        const { candidate } = event;
        console.log("Generated new ICE candidate", candidate);
        this.onIce(candidate);
    }

    addIceCandidate(iceCandidate) {
        if (iceCandidate == null) {
            console.log("Recieved ICE candidate was null");
            return;
        }
        
        return this.peerConnection.addIceCandidate(iceCandidate);
    }

    closeConnections(event) {
        console.log("Closing connections, desposing of objects");
        if (this.dataChannel) this.dataChannel.close();
        this.peerConnection.close();
        this.peerConnection = null;
        console.log("Connections closed");

        if (event && this.peerConnection != null) { // Un-clean exit; this.peerConnection is nulled when the exit is clean
            console.warn("Close event:", event)
            Notify.error("Connection to peer lost, please try again")
        }
    }
}